import { Link } from "gatsby"
import * as React from "react"
import Header from "../components/Header"
import Palapeli from "../components/Palapeli"
import { UserContext } from "../context/UserContext"
import { STORAGE_KEY as palapeli_key } from "../components/Palapeli"
import { STORAGE_KEY as avatut_key } from "../components/Joulukalenteri"
import Lomake from "../components/Lomake"
import { devMode } from "./_etusivu-kalenteri"
import Footer from "../components/Footer"
import SEO, { gtag } from "../components/SEO"
import { endOfCampaign, now } from "."

const Page = () => {
  const [context, setContext] = React.useContext(UserContext)
  const lomake = React.useRef(null)

  const campaignHasEnded = now > endOfCampaign //|| devMode

  const isFinished = devMode
    ? context[avatut_key].filter(itm => typeof itm === "number").length ===
      context[palapeli_key].filter(pala => pala.correct).length
    : !context[palapeli_key].find(pala => !pala.correct)

  React.useEffect(() => {
    if (isFinished) {
      lomake.current.scrollIntoView({ behavior: "smooth" })

      gtag("Palapeli", "valmis")
    }
  }, [isFinished])

  const resetPuzzle = () => {
    const palat = context[palapeli_key]
    palat.forEach(pala => {
      pala.onBoard = false
    })
    setContext({ palat })
    gtag("Palapeli", "click", "Aloita alusta")
  }

  return (
    <>
      <SEO title="Rakenna palapeli" description="Voit voittaa 250 euron arvoisen S-ryhmän majoituslahjakortin." />
      <Header />
      <main>
        {devMode && <p>! Test mode active !</p>}

        {campaignHasEnded ? (
          <>
            <h1>Rakenna JouLumme palapeli</h1>
            <p>
              Raahaa palapelin pala paikalleen hiirellä tai sormella vetämällä. Saat uuden palan jokaisesta
              kalenteriluukusta.
            </p>
          </>
        ) : (
          <>
            <p className="pre-heading">Rakenna palapeli:</p>
            <h1>Osallistu arvontaan, jossa palkintona on 250 euron arvoinen S-ryhmän majoituslahjakortti!</h1>
            <p>
              Raahaa palapelin pala paikalleen hiirellä tai sormella vetämällä. Saat uuden palan jokaisesta
              kalenteriluukusta aina aattoon asti. Koottuasi palapelin voit osallistua pääpalkinnon arvontaan!
            </p>
          </>
        )}

        <Palapeli />

        {isFinished && (
          <div ref={lomake} style={{ margin: "4em 0" }}>
            {campaignHasEnded ? (
              <>
                <h2>Hurraa! Kokosit koko palapelin!</h2>
              </>
            ) : (
              <>
                <Lomake draw="palapeli" apiUrl="draw">
                  <div>
                    <h2>Hurraa! Kokosit koko palapelin!</h2>
                    <p>
                      Osallistu pääpalkinnon arvontaan syöttämällä sähköpostiosoitteesi sille varattuun kenttään.
                      Käytämme yhteystietojasi vain pääpalkinnon arvontaan liittyvään kontaktointiin. Pääpalkinto
                      arvotaan 3.1.2022.
                    </p>
                  </div>
                </Lomake>
              </>
            )}
          </div>
        )}

        <nav className="bottom-nav">
          <Link to="/">
            <button>Etusivulle</button>
          </Link>
          <button onClick={resetPuzzle}>Aloita alusta</button>
        </nav>
      </main>
      <Footer />
    </>
  )
}

export default Page
